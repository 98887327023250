import React from 'react'
import { Container } from 'react-bootstrap'

export const Home = () => {
  return (
    <Container className="content">
    <h1>This is home</h1>
    </Container>
  )
}
