import { useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin, userSelector,clearState } from "../features/userSlice"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import Card from 'react-bootstrap/Card';
import { Col, Container, Row } from "react-bootstrap";

export  const Login = () =>  {

    const {  isError,errMessage,userInfo } = useSelector(userSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register,handleSubmit } = useForm()  
    
    const onSubmit = (data) => {      
               dispatch(userLogin(data));
      };

      useEffect(() => {
        if (userInfo) {
          dispatch(clearState());
          navigate('/home')
        }
        if(isError){
            toast.error(errMessage);
            dispatch(clearState());
        }
      }, [navigate, userInfo,errMessage,isError,dispatch])
    
    
    return (
        <div>
          {!userInfo && 
            (
              <Container className="content">
                <Toaster />
                <Row>
                  <Col></Col >
                  <Col md="10">
                <Card className="p-5 my-3" >
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" {...register('email')}
                  placeholder="Enter email" required/>
                </Form.Group>
          
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control type="password" {...register('password')} placeholder="Password" required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Remember me" />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
              </Card>
              </Col>
              <Col></Col >
              </Row>
              </Container>
            )
         }
         </div>
    );
}