import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Container} from 'react-bootstrap';


export const Header = () => {

  return (
    
      <Navbar bg="primary" variant="dark" collapseOnSelect>
         
        <Container>
        <Navbar.Brand href="#home">Leads Robo</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Nav className="ml-auto" >    
        
            <NavDropdown align="end"   title="Khuram" >
              <NavDropdown.Item href="#action3"><i className='fas fa-user'/> My Account</NavDropdown.Item>
              <NavDropdown.Item href="#action4"> <i className='fas fa-money-bill'/> Billing </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5"> <i className='fas fa-right-from-bracket'/> Signout </NavDropdown.Item>
            </NavDropdown>
            
          </Nav>
          </Container>
      </Navbar>
    
  );
}
