import React from 'react';
import { Header } from './components/layout/Header';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './App.scss';
import { Footer } from './components/layout/Footer';
import {Home} from './components/Home'
import {Login} from './components/Login'


function App() {
  return (
    <div className="App">
        <>
        <Router>
        <Header></Header>
                <Routes >
                   <Route  path="/" element={<Home/>}></Route>   
                   <Route  path="/home" element={<Home/>}></Route>  
                   <Route  path="/login" element={<Login/>}></Route>
                </Routes>
                <Footer></Footer>
            </Router>
          </>
    </div>
  );
}

export default App;
